import React, { createContext, useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import Router from "next-translate/Router";

// Apollo
import { initializeApollo } from "../apollo";
import gql from "graphql-tag";

// components
import Loading from "../components/common/Loading";

export const USER_FIELDS = gql`
  fragment UserFields on UserNode {
    id
    pk
    email
    fullName
  }
`;

const ME = gql`
  query {
    me {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const client = initializeApollo();

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get("token");
      if (token) {
        const {
          data: { me },
        } = await client.query({ query: ME });
        if (me) setUser(me);
      }
      setLoading(false);
    }
    loadUserFromCookies();
  }, []);

  const logout = () => {
    Router.replaceI18n("/login");
    Cookies.remove("token");
    setUser(null);
  };

  const login = ({ token, user }) => {
    Cookies.set("token", token, { expires: 60 });
    setUser(user);
    Router.replaceI18n("/");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!user, user, loading, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}

export function ProtectRoute(Component) {
  return () => {
    const { isAuthenticated, loading } = useAuth();

    if (!isAuthenticated && !loading) Router.pushI18n("/login");

    if (loading || !isAuthenticated) return <Loading />;

    return <Component {...arguments} />;
  };
}
